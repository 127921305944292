import { FC, useContext, useReducer, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { gql, useLazyQuery } from '@apollo/client';
import { useDVCClient } from '@devcycle/devcycle-react-sdk';

import UserContext from './UserContext';
import UserReducer from './UserReducer';
import { UserStateProps } from '../../interfaces';
import { UserActionType } from '../action';
import AlertContext from '../Alert/AlertContext';
import { useRudderstack } from '../../contexts/Rudderstack/RudderstackContext';

const USER_QUERY = gql`
  query Query {
    user {
      id
      name
      email
      company
      phone_number
    }
  }
`;

const UserState: FC = props => {
  if (typeof window !== 'undefined') {
    // Perform localStorage action
    var token = localStorage?.getItem('currentUser');
    var id = JSON.parse(token)?.id;
    var email = JSON.parse(token)?.email;
    var name = JSON.parse(token)?.name;
  }

  const dvcClient = useDVCClient();
  const rudderanalytics = useRudderstack();

  const [getProfile, { data: profile, error: profileError }] =
    useLazyQuery(USER_QUERY);

  const userState = token
    ? {
        id,
        email,
        name,
        profile: null,
      }
    : {
        id: null,
        email: null,
        name: null,
        profile: null,
      };

  const initialState: UserStateProps = {
    dvcClient,
    rudderanalytics,
    currentUser: userState,
    isLoggedIn: !!email,
  };
  const [userstate, dispatch] = useReducer(UserReducer, initialState);
  const { setAlert } = useContext(AlertContext);

  const { user, error: userError, isLoading: userIsLoading } = useUser();

  useEffect(() => {
    if (!userIsLoading) {
      if (dvcClient) {
        if (user?.email) {
          if (rudderanalytics) {
            dispatch({
              type: UserActionType.LOGIN,
              payload: {
                currentUser: {
                  email: user.email,
                  name: user.name,
                  profile: null,
                },
                dvcClient,
                rudderanalytics,
              },
            });

            getProfile();
          }
        } else {
          dispatch({
            type: UserActionType.LOG_OUT,
            payload: { dvcClient },
          });
        }
      }
      if (userError) {
        dispatch({
          type: UserActionType.LOG_OUT,
          payload: { dvcClient },
        });
        console.error(userError);
        setAlert('Login Error', 'error');
      }
    }
  }, [user, userError, userIsLoading, dvcClient, rudderanalytics]);

  useEffect(() => {
    if (rudderanalytics && profile?.user?.email) {
      dispatch({
        type: UserActionType.UPDATE_PROFILE,
        payload: {
          profile: {
            id: profile.user.id,
            email: profile.user.email,
            name: profile.user.name,
            company: profile.user.company,
            phoneNumber: profile.user.phone_number,
          },
          rudderanalytics,
        },
      });
    }
    if (profileError) {
      dispatch({
        type: UserActionType.UPDATE_PROFILE,
        payload: {
          profile: null,
          rudderanalytics,
        },
      });
      console.error(profileError);
      setAlert('Profile Error', 'error');
    }
  }, [profile, profileError, rudderanalytics]);

  return (
    <UserContext.Provider
      value={{
        userstate,
      }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
