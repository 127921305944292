import { sendTokenToChromeExtension } from '../../helpers/chrome';
import { getExtensionId } from '../../lib/config';
import { UserStateAction, UserStateProps } from '../../interfaces';
import { UserActionType } from '../action';

const UserReducer = (
  state: UserStateProps,
  action: UserStateAction,
): UserStateProps => {
  switch (action.type) {
    case UserActionType.LOGIN: {
      const { currentUser, dvcClient, rudderanalytics } = action.payload;

      localStorage.setItem('currentUser', JSON.stringify(currentUser));

      sendTokenToChromeExtension({
        extensionId: getExtensionId(),
        jwt: '1234567890',
      });

      dvcClient.identifyUser({
        email: currentUser.email,
      });

      rudderanalytics.track('login');

      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case UserActionType.LOG_OUT:
      localStorage.removeItem('currentUser');
      sendTokenToChromeExtension({
        extensionId: getExtensionId(),
        jwt: null,
      });
      action.payload.dvcClient.resetUser();

      return {
        ...state,
        isLoggedIn: false,
        currentUser: null,
      };
    case UserActionType.UPDATE_PROFILE: {
      const { profile, rudderanalytics } = action.payload;
      if (profile) {
        rudderanalytics.identify(profile.id, {
          email: profile.email,
          name: profile.name,
        });
      }

      return {
        ...state,
        currentUser: { ...state.currentUser, profile },
      };
    }
    default: {
      return state;
    }
  }
};

export default UserReducer;
