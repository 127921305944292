/* eslint-disable */
export enum WalletActionType {
  CONNECT_WALLET = 'CONNECT_WALLET',
  DISCONNECT_WALLET = 'DISCONNECT_WALLET',
  SET_WEB3_PROVIDER = 'SET_WEB3_PROVIDER',
  LOGIN = 'LOGIN',
  LOG_OUT = 'LOG_OUT',
}
export enum AlertActionType {
  ALERTS = 'ALERTS',
}
export enum UserActionType {
  LOGIN = 'LOGIN',
  LOG_OUT = 'LOG_OUT',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
}
