import getConfig from 'next/config';
import fetch from 'sync-fetch';

const { serverRuntimeConfig } = getConfig();

let loaded = false;
let cachedConfig = {};

const initConfig = () => {
  if (!loaded) {
    let config;
    if (process.browser) {
      config = fetch(`//${window.location.host}/api/config`, {}).json();
    } else {
      config = serverRuntimeConfig;
    }
    if (
      !config ||
      (config &&
        Object.keys(config).length === 0 &&
        config.constructor === Object)
    ) {
      // Need to use console.error as getEnvironment is used inside the Log library
      console.error('No config'); // eslint-disable-line no-console
      return;
    }
    cachedConfig = config;
  }
};

initConfig();

export const getConfigValue = name => {
  return cachedConfig[name];
};

export const getEnvironment = () => {
  return getConfigValue('environment');
};

export const getDevcycleApiKey = () => {
  return getConfigValue('devcycleApiKey');
};

export const getSentryDSN = () => {
  return getConfigValue('sentryDSN');
};

export const getSentryTracesSampleRate = () => {
  let rate = 0;
  try {
    rate = Number(getConfigValue('sentryTracesSampleRate'));
  } catch (err) {} // eslint-disable-line no-empty
  return rate;
};

export const getAppServer = () => {
  return getConfigValue('appServer');
};

export const getExtensionId = () => {
  return getConfigValue('extensionId');
};

export const getOpenseaBaseUrl = () => {
  return getConfigValue('openseaBaseUrl');
};

export const getRudderstackWriteKey = () => {
  return getConfigValue('rudderstackWriteKey');
};

export const getRudderstackPlanUrl = () => {
  return getConfigValue('rudderstackPlanUrl');
};
