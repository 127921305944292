import { createContext, useContext } from 'react';
import { UserStateProps } from '../../interfaces';

export type UserContextProps = {
  userstate?: UserStateProps;
};

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export function useUser() {
  return useContext(UserContext);
}

export default UserContext;
