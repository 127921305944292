import React, { createContext, useState, useEffect, useContext } from 'react';

import {
  getRudderstackWriteKey,
  getRudderstackPlanUrl,
} from '../../lib/config';

export const RudderstackContext = createContext(null);

export const useRudderstack = () => useContext(RudderstackContext);

export const RudderstackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [rudderanalytics, setRudderanalytics] = useState(null);

  useEffect(() => {
    const rudderstackInit = async () => {
      window.rudderanalytics = await import('rudder-sdk-js');

      window.rudderanalytics.load(
        getRudderstackWriteKey() || '',
        getRudderstackPlanUrl() || '',
        {
          integrations: { All: true }, // load call options
        },
      );

      window.rudderanalytics.ready(() => {
        setRudderanalytics(window.rudderanalytics);
      });
    };

    rudderstackInit().catch(console.error);
  }, []);

  return (
    <RudderstackContext.Provider value={rudderanalytics}>
      {children}
    </RudderstackContext.Provider>
  );
};
