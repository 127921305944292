import { createContext } from 'react';
import { AlertProps, Color } from '../../interfaces';

export type AlertContextProps = {
  alert:
    | AlertProps
    | {
        id: number;
        message: string;
        variant: Color;
      };
  // eslint-disable-next-line
  setAlert: (message: string, variant: Color) => void;
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);
export default AlertContext;
