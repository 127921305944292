/* global chrome */
export const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
  if (typeof window !== 'undefined') {
    if (window.chrome) {
      chrome.runtime?.sendMessage(extensionId, { jwt }, response => {
        if (!response.success) {
          return response;
        }
      });
    }
  }
};
