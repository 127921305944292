import React, { FC, useReducer } from 'react';
import AlertContext from './AlertContext';
import AlertReducer from './AlertReducer';
import { AlertActionType } from '../action';
import { AlertProps, Color } from '../../interfaces';

const AlertState: FC = props => {
  const initialState: AlertProps = {
    id: null,
    message: null,
    variant: null,
  };
  const [state, dispatch] = useReducer(AlertReducer, initialState);

  const setAlert = (message: string, variant: Color) => {
    const id = Math.random();
    dispatch({
      type: AlertActionType.ALERTS,
      payload: { id, message, variant },
    });
  };
  return (
    <AlertContext.Provider
      value={{
        alert: state,
        setAlert,
      }}>
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
