import { useToast } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import AlertContext from '../store/Alert/AlertContext';

const Alerts = () => {
  const toast = useToast();
  const { alert } = useContext(AlertContext);
  useEffect(() => {
    if (alert.id) {
      if (!toast.isActive(alert.id)) {
        toast({
          id: alert.id,
          title: alert.message,
          position: 'top-right',
          status: alert.variant,
          isClosable: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [alert]);
  return <></>;
};

export default Alerts;
