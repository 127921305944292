import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Button, Heading, VStack, Text } from '@chakra-ui/react';

import { getEnvironment } from '../lib/config';
import { useUser } from 'store/User/UserContext';
import { BROWSE_COLLECTIONS_ROUTE } from 'config/routes';

interface Props {
  error: any;
  componentStack: any;
  resetError: any;
}

export default function ErrorPage({
  error,
  componentStack,
  resetError,
}: Props) {
  const { push } = useRouter();
  const [resetPage, setResetPage] = useState<string | undefined>(undefined);
  const [displayErrorDetails, setDisplayErrorDetails] =
    useState(componentStack);
  useEffect(() => {
    const displayError = sessionStorage.getItem('errorDetails');
    if (sessionStorage.getItem('errorDetails')) {
      setDisplayErrorDetails(displayError);
    }
  }, []);
  useEffect(() => {
    if (resetPage) {
      push(resetPage);
    }
  }, [push, resetPage]);

  const { userstate } = useUser();
  const resetClick = () => {
    sessionStorage.clear();
    if (resetError) {
      resetError();
    } else {
      if (userstate?.currentUser?.email) {
        setResetPage(BROWSE_COLLECTIONS_ROUTE);
      } else {
        setResetPage('/');
      }
    }
  };
  return (
    <>
      <Head>
        <title>Error | NVal</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <VStack align="center" py={10}>
        <Heading pb={4} maxW={500}>
          Woops! Looks like there&apos;s been an error on our end.
        </Heading>
        <Text mb={2} maxW={500} align="center">
          Sorry about that. Seems like something isn&apos;t quite right. For
          now, we recommend refreshing the page or coming back later.
        </Text>
        <Text mb={1} maxW={500} align="center">
          {error && error.message}
        </Text>
        {getEnvironment() !== 'prod' && (
          <Text mb={1} maxW={500} align="center">
            {displayErrorDetails}
          </Text>
        )}
        <Button variant="secondary" onClick={() => resetClick()}>
          Click here to reset!
        </Button>
      </VStack>
    </>
  );
}
