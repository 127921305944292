import Head from 'next/head';
import { withDVCProvider } from '@devcycle/devcycle-react-sdk';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { UserProvider } from '@auth0/nextjs-auth0/client';

import UserState from '../store/User/UserState';
import { customTheme } from '../helpers/theme';
import '../styles/globals.css';
import AlertState from '../store/Alert/AlertState';
import Alerts from '../helpers/Alerts';
import { getDevcycleApiKey } from '../lib/config';
import ErrorPage from './error';
import { RudderstackProvider } from '../contexts/Rudderstack/RudderstackContext';

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache(),
});

function NvalApp({ Component, pageProps }: any) {
  return (
    <RudderstackProvider>
      <ChakraProvider resetCSS theme={customTheme}>
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <ErrorPage
              error={error}
              componentStack={componentStack}
              resetError={resetError}
              {...pageProps}
            />
          )}>
          <AlertState>
            <Alerts />
            <UserProvider>
              <ApolloProvider client={client}>
                <UserState>
                  <Head>
                    <title>NVal Web App</title>
                  </Head>
                  <Component {...pageProps} />
                </UserState>
              </ApolloProvider>
            </UserProvider>
          </AlertState>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </RudderstackProvider>
  );
}

export default withDVCProvider({ sdkKey: getDevcycleApiKey() })(NvalApp);
