export const HOME_ROUTE = '';
export const NFT_DETAILS_ROUTE = `${HOME_ROUTE}/nft`;
export const BROWSE_COLLECTIONS_ROUTE = `${HOME_ROUTE}/browse-collections`;
export const PROFILE_ROUTE = `${HOME_ROUTE}/profile`;
export const NFTS_LIST_ROUTE = `${HOME_ROUTE}nft/collection/0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38`;
export const NFT_DETAIL_TEST_SINGLE_ROUTE = `collectionAddress=0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38&nftId=99998`;
export const NFTS_LIST_TEST_ROUTE = `/nfts?&limit=12&collectionId=0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d&pageKey=`;
export const MY_PORTFOLIO_NFTS_LIST_TEST_ROUTE = `owner=0`;
export const TRANSACTION_HISTORY_TEST_ROUTE = `/nft-transaction-history?collectionId=0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38&nftId=4219&offset=50&limit=10`;
export const HISTORICAL_PRICE_GRAPH_TEST_ROUTE = `/historical-price-prediction?collectionId=0xaed3311bbdea39956c8b02ef01b30bb9719e63f6&nftId=130&startDate=2022-06-16T16:00:00&endDate=2022-06-16T20:00:00&interval=1h`;
