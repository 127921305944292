import { extendTheme } from '@chakra-ui/react';
import { theme as chakraTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const customColors = {
  black: '#1D1D1D',
  white: '#ffffff',
  grey: '#6B6B73',
  dgrey: '#6B6B73',
  lgrey: '#DDDDDD',
  red: '#E51A2B',
  skyblue: '#0C95E7',
  purple: '#5463EB',
};

const fonts = {
  ...chakraTheme.fonts,
  body: `'Poppins', sans-serif`,
  heading: `'Poppins', sans-serif`,
};

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
});

const overrides = {
  ...chakraTheme.fonts,
  fonts,
  customColors,
  breakpoints,
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '40px',
    '6xl': '44px',
    '7xl': '48px',
    '8xl': '52px',
    '9xl': '56px',
  },
  colors: {
    brand: {
      500: customColors.black,
      400: customColors.black,
    },
    gray: {
      400: customColors.black,
      500: customColors.dgrey,
    },
    skyblue: {
      400: customColors.skyblue,
    },
    purple: {
      400: customColors.purple,
    },
  },
  radii: {
    none: '0',
    sm: '16px',
    base: '0.25rem',
    md: '24px',
    lg: '50px',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        md: {
          h: '56px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        // 4. We can override existing variants
        solid: {
          borderColor: customColors.lgrey,
          _hover: {
            backgroundColor: customColors.grey,
            color: customColors.white,
          },
          // bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        },
        outline: {
          borderColor: customColors.lgrey,
          _hover: {
            backgroundColor: customColors.black,
            color: customColors.white,
          },
          // bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          button: {
            mt: '8px',
          },
        },
      },
    },
    Skeleton: {
      baseStyle: {
        marginBottom: '6',
      },
      defaultProps: {
        startColor: '#dcdcdc',
        endColor: '#91a3b0',
      },
    },
  },
};

const customTheme = extendTheme(overrides);

export { customTheme, customColors };
