import { AlertProps, AlertStateAction } from '../../interfaces';
import { AlertActionType } from '../action';

const AlertReducer = (state: AlertProps, action: AlertStateAction) => {
  switch (action.type) {
    case AlertActionType.ALERTS:
      return action.payload;
    default:
      return state;
  }
};

export default AlertReducer;
